.omrs-toasts-container {
  z-index: 100000;
  position: fixed;
  width: 100%;
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.omrs-toast {
  background-color: #323233;
  color: var(--omrs-color-ink-white) !important;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 12.875rem rgba(0, 0, 0, 0.25);
  padding: 0 1rem;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 200ms ease-in-out, padding 100ms ease-in-out;
}

.omrs-toast + .omrs-toast {
  margin-top: 1rem;
}

.omrs-toast-mounting,
.omrs-toast-mounting > * {
  padding: 0;
}

.omrs-toast-closing,
.omrs-toast-mounting {
  opacity: 0;
}

.omrs-toast .omrs-btn-icon {
  border-left: 0.0625rem solid var(--omrs-color-ink-white);
  fill: var(--omrs-color-ink-white) !important;
}

.omrs-toast > * {
  padding: 0.75rem 0;
}

.omrs-toast > *:not(:last-child) {
  padding-right: 1rem;
  border-right: 0.0625rem solid var(--omrs-color-ink-white);
}

.omrs-toast > *:not(:first-child) {
  padding-left: 1rem;
}
