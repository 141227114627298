/* See https://docs.google.com/presentation/d/1YqMMW0Cq5siE_9FG-ipWTzF73KToIw7ewmr1Y8VAjJ8/edit#slide=id.g5bf07c575d_0_0
*/

* {
  box-sizing: border-box;
}

.omrs-padding-0 {
  padding: 0;
}

.omrs-padding-4 {
  padding: 0.25rem;
}

.omrs-padding-8 {
  padding: 0.5rem;
}

.omrs-padding-12 {
  padding: 0.75rem;
}

.omrs-padding-16 {
  padding: 1rem;
}

.omrs-padding-20 {
  padding: 1.25rem;
}

.omrs-padding-24 {
  padding: 1.5rem;
}

.omrs-padding-28 {
  padding: 1.75rem;
}

.omrs-padding-32 {
  padding: 2rem;
}

.omrs-padding-left-0 {
  padding-left: 0;
}

.omrs-padding-left-4 {
  padding-left: 0.25rem;
}

.omrs-padding-left-8 {
  padding-left: 0.5rem;
}

.omrs-padding-left-12 {
  padding-left: 0.75rem;
}

.omrs-padding-left-16 {
  padding-left: 1rem;
}

.omrs-padding-left-20 {
  padding-left: 1.25rem;
}

.omrs-padding-left-24 {
  padding-left: 1.5rem;
}

.omrs-padding-left-28 {
  padding-left: 1.75rem;
}

.omrs-padding-left-32 {
  padding-left: 2rem;
}

.omrs-padding-right-0 {
  padding-right: 0;
}

.omrs-padding-right-4 {
  padding-right: 0.25rem;
}

.omrs-padding-right-8 {
  padding-right: 0.5rem;
}

.omrs-padding-right-12 {
  padding-right: 0.75rem;
}

.omrs-padding-right-16 {
  padding-right: 1rem;
}

.omrs-padding-right-20 {
  padding-right: 1.25rem;
}

.omrs-padding-right-24 {
  padding-right: 1.5rem;
}

.omrs-padding-right-28 {
  padding-right: 1.75rem;
}

.omrs-padding-right-32 {
  padding-right: 2rem;
}

.omrs-padding-top-0 {
  padding-top: 0;
}

.omrs-padding-top-4 {
  padding-top: 0.25rem;
}

.omrs-padding-top-8 {
  padding-top: 0.5rem;
}

.omrs-padding-top-12 {
  padding-top: 0.75rem;
}

.omrs-padding-top-16 {
  padding-top: 1rem;
}

.omrs-padding-top-20 {
  padding-top: 1.25rem;
}

.omrs-padding-top-24 {
  padding-top: 1.5rem;
}

.omrs-padding-top-28 {
  padding-top: 1.75rem;
}

.omrs-padding-top-32 {
  padding-top: 2rem;
}

.omrs-padding-bottom-0 {
  padding-bottom: 0;
}

.omrs-padding-bottom-4 {
  padding-bottom: 0.25rem;
}

.omrs-padding-bottom-8 {
  padding-bottom: 0.5rem;
}

.omrs-padding-bottom-12 {
  padding-bottom: 0.75rem;
}

.omrs-padding-bottom-16 {
  padding-bottom: 1rem;
}

.omrs-padding-bottom-20 {
  padding-bottom: 1.25rem;
}

.omrs-padding-bottom-24 {
  padding-bottom: 1.5rem;
}

.omrs-padding-bottom-28 {
  padding-bottom: 1.75rem;
}

.omrs-padding-bottom-32 {
  padding-bottom: 2rem;
}

.omrs-margin-0 {
  margin: 0;
}

.omrs-margin-4 {
  margin: 0.25rem;
}

.omrs-margin-8 {
  margin: 0.5rem;
}

.omrs-margin-12 {
  margin: 0.75rem;
}

.omrs-margin-16 {
  margin: 1rem;
}

.omrs-margin-20 {
  margin: 1.25rem;
}

.omrs-margin-24 {
  margin: 1.5rem;
}

.omrs-margin-28 {
  margin: 1.75rem;
}

.omrs-margin-32 {
  margin: 2rem;
}

.omrs-margin-left-0 {
  margin-left: 0;
}

.omrs-margin-left-4 {
  margin-left: 0.25rem;
}

.omrs-margin-left-8 {
  margin-left: 0.5rem;
}

.omrs-margin-left-12 {
  margin-left: 0.75rem;
}

.omrs-margin-left-16 {
  margin-left: 1rem;
}

.omrs-margin-left-20 {
  margin-left: 1.25rem;
}

.omrs-margin-left-24 {
  margin-left: 1.5rem;
}

.omrs-margin-left-28 {
  margin-left: 1.75rem;
}

.omrs-margin-left-32 {
  margin-left: 2rem;
}

.omrs-margin-right-0 {
  margin-right: 0;
}

.omrs-margin-right-4 {
  margin-right: 0.25rem;
}

.omrs-margin-right-8 {
  margin-right: 0.5rem;
}

.omrs-margin-right-12 {
  margin-right: 0.75rem;
}

.omrs-margin-right-16 {
  margin-right: 1rem;
}

.omrs-margin-right-20 {
  margin-right: 1.25rem;
}

.omrs-margin-right-24 {
  margin-right: 1.5rem;
}

.omrs-margin-right-28 {
  margin-right: 1.75rem;
}

.omrs-margin-right-32 {
  margin-right: 2rem;
}

.omrs-margin-top-0 {
  margin-top: 0;
}

.omrs-margin-top-4 {
  margin-top: 0.25rem;
}

.omrs-margin-top-8 {
  margin-top: 0.5rem;
}

.omrs-margin-top-12 {
  margin-top: 0.75rem;
}

.omrs-margin-top-16 {
  margin-top: 1rem;
}

.omrs-margin-top-20 {
  margin-top: 1.25rem;
}

.omrs-margin-top-24 {
  margin-top: 1.5rem;
}

.omrs-margin-top-28 {
  margin-top: 1.75rem;
}

.omrs-margin-top-32 {
  margin-top: 2rem;
}

.omrs-margin-bottom-0 {
  margin-bottom: 0;
}

.omrs-margin-bottom-4 {
  margin-bottom: 0.25rem;
}

.omrs-margin-bottom-8 {
  margin-bottom: 0.5rem;
}

.omrs-margin-bottom-12 {
  margin-bottom: 0.75rem;
}

.omrs-margin-bottom-16 {
  margin-bottom: 1rem;
}

.omrs-margin-bottom-20 {
  margin-bottom: 1.25rem;
}

.omrs-margin-bottom-24 {
  margin-bottom: 1.5rem;
}

.omrs-margin-bottom-28 {
  margin-bottom: 1.75rem;
}

.omrs-margin-bottom-32 {
  margin-bottom: 2rem;
}
